// src/pages/AdminHomeScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const AdminHomeScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminAccess = async () => {
      if (!user.isAuthenticated) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/admin/check-admin-status?user_id=${user.user_id}`);
        
        if (!response.ok) {
          throw new Error('Failed to verify admin status');
        }

        const data = await response.json();
        if (!data.is_admin) {
          navigate('/login');
          return;
        }
      } catch (err) {
        console.error('Error:', err);
        setError(err.message || 'An error occurred');
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminAccess();
  }, [user.isAuthenticated, user.user_id, navigate]);

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to="/myaccount" style={styles.link}>
          &lt;&lt; Back to My Account
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Admin Home
      </h1>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center'
      }}>
        <Link
          to="/default-voices"
          style={{
            ...styles.button,
            textDecoration: 'none',
            width: '200px',
            textAlign: 'center'
          }}
        >
          Default Voices
        </Link>
      </div>
    </div>
  );
};

export default AdminHomeScreen;