// src/components/ScriptAudioTable.js

import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Play, Loader } from 'lucide-react';

const ScriptAudioTable = ({ storyId, parsedLines }) => {
  const [audioStatus, setAudioStatus] = useState({});
  const [error, setError] = useState(null);
  const [currentLine, setCurrentLine] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    checkExistingAudio();
  }, [storyId, user.user_id]);

  const checkExistingAudio = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/script-audio/get-existing-audio/${storyId}?user_id=${user.user_id}`
      );

      if (!response.ok) {
        throw new Error('Failed to check existing audio');
      }

      const data = await response.json();
      if (Object.keys(data.existing_audio).length > 0) {
        setAudioStatus(data.existing_audio);
      }
      setHasInitialized(true);
    } catch (err) {
      console.error('Error checking existing audio:', err);
      setError('Failed to check existing audio');
      setHasInitialized(true);
    }
  };

  const startAudioGeneration = async () => {
    setIsGenerating(true);
    setError(null);
    
    // Reset all line statuses
    const initialStatus = {};
    parsedLines.forEach(line => {
      if (line.character_name !== "Non-speaking part") {
        initialStatus[line.line_number] = { progress: 0 };
      }
    });
    setAudioStatus(initialStatus);

    try {
      // Start the generation process
      const response = await fetch(`${API_BASE_URL}/script-audio/start-generation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          story_id: storyId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to start audio generation');
      }

      // Set up SSE connection for progress updates
      const eventSource = new EventSource(`${API_BASE_URL}/script-audio/progress/${storyId}?user_id=${user.user_id}`);
      
      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.complete) {
          eventSource.close();
          setIsGenerating(false);
          return;
        }
        setAudioStatus(current => ({
          ...current,
          [data.line_number]: {
            progress: data.progress,
            url: data.url
          }
        }));
      };

      eventSource.onerror = () => {
        eventSource.close();
        setIsGenerating(false);
      };

    } catch (err) {
      setError(err.message);
      setIsGenerating(false);
    }
  };

  const playAudio = (lineId) => {
    const audio = new Audio(audioStatus[lineId]?.url);
    audio.play();
    setCurrentLine(lineId);
    audio.onended = () => setCurrentLine(null);
  };

  const renderProgressOrPlayButton = (line) => {
    const status = audioStatus[line.line_number];
    
    if (!status) return null;

    if (status.url) {
      return (
        <button
          onClick={() => playAudio(line.line_number)}
          style={styles.playButton}
          disabled={currentLine === line.line_number}
        >
          <Play size={16} />
        </button>
      );
    }

    return (
      <div style={{ position: 'relative', width: '40px', height: '20px' }}>
        <div style={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          width: '100%',
          height: '4px',
          backgroundColor: '#333',
          borderRadius: '2px',
        }}>
          <div style={{
            width: `${status.progress}%`,
            height: '100%',
            backgroundColor: '#007BFF',
            borderRadius: '2px',
            transition: 'width 0.3s ease'
          }} />
        </div>
        <span style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '0.7rem',
          color: '#fff'
        }}>
          {status.progress}%
        </span>
      </div>
    );
  };

  if (error) return <div style={styles.error}>{error}</div>;
  if (!hasInitialized) return <div>Loading audio status...</div>;

  return (
    <div style={{ marginTop: '40px' }}>
      <h2 style={styles.formTitle}>Generated Audio</h2>
      
      {/* Generate Audio button - always shown when not generating */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
      }}>
        <button
          onClick={startAudioGeneration}
          style={{
            ...styles.button,
            backgroundColor: '#007BFF',
            fontSize: '1.1rem',
            padding: '12px 24px',
            opacity: isGenerating ? 0.7 : 1
          }}
          disabled={isGenerating}
        >
          {isGenerating ? 'Generating Audio...' : 'Generate Script Audio'}
        </button>
      </div>
      
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.tableHeader}>Line</th>
            <th style={styles.tableHeader}>Character</th>
            <th style={styles.tableHeader}>Dialogue</th>
            <th style={styles.tableHeader}>Progress/Play</th>
          </tr>
        </thead>
        <tbody>
          {parsedLines.map((line) => (
            line.character_name !== "Non-speaking part" && (
              <tr key={line.line_number}>
                <td style={styles.tableCell}>{line.line_number}</td>
                <td style={styles.tableCell}>{line.character_name}</td>
                <td style={styles.tableCell}>{line.dialogue_text}</td>
                <td style={styles.tableCell}>
                  {renderProgressOrPlayButton(line)}
                </td>
              </tr>
            )
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScriptAudioTable;