// src/components/ScriptCharactersTable.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Volume2 } from 'lucide-react';

const ScriptCharactersTable = ({ storyId, parsedLines, projectId }) => {
  const [scriptCharacters, setScriptCharacters] = useState([]);
  const [projectCharacters, setProjectCharacters] = useState([]);
  const [mappings, setMappings] = useState({});
  const [voiceSettings, setVoiceSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const uniqueCharacters = [...new Set(
      parsedLines
        .filter(line => line.character_name !== "Non-speaking part")
        .map(line => line.character_name)
    )];
    setScriptCharacters(uniqueCharacters);

    const initializeData = async () => {
      await fetchProjectCharacters();
      await fetchExistingMappings();
    };

    initializeData();
  }, [parsedLines, projectId, storyId, user.user_id]);

  useEffect(() => {
    const fetchAllVoiceSettings = async () => {
      const settings = {};
      const mappedCharacterIds = Object.values(mappings).filter(Boolean);
      
      for (const characterId of mappedCharacterIds) {
        try {
          const response = await fetch(
            `${API_BASE_URL}/voice_gen/load-voice-settings/${characterId}?user_id=${user.user_id}`
          );
          
          if (response.ok) {
            const data = await response.json();
            if (data.currentSettings) {
              settings[characterId] = {
                name: data.currentSettings.name
              };
            }
          }
        } catch (err) {
          console.error(`Failed to load voice settings for character ${characterId}:`, err);
        }
      }
      
      setVoiceSettings(settings);
    };

    if (Object.keys(mappings).length > 0) {
      fetchAllVoiceSettings();
    }
  }, [mappings, user.user_id]);

  const fetchProjectCharacters = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/character/list?project_id=${projectId}&user_id=${user.user_id}`);
      if (!response.ok) throw new Error('Failed to fetch project characters');
      const data = await response.json();
      setProjectCharacters(data.characters || []);
      return data.characters || [];
    } catch (err) {
      setError('Failed to load project characters');
      return [];
    }
  };

  const fetchExistingMappings = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/script-parsing/get-character-mappings/${storyId}?user_id=${user.user_id}`);
      if (!response.ok) throw new Error('Failed to fetch character mappings');
      const data = await response.json();
      
      if (Object.keys(data.mappings || {}).length === 0) {
        await getSuggestedMappings();
      } else {
        setMappings(data.mappings);
      }
    } catch (err) {
      setError('Failed to load character mappings');
    } finally {
      setIsLoading(false);
    }
  };

  const getSuggestedMappings = async () => {
    try {
      const uniqueCharacters = [...new Set(
        parsedLines
          .filter(line => line.character_name !== "Non-speaking part")
          .map(line => line.character_name)
      )];

      const response = await fetch(`${API_BASE_URL}/script-parsing/suggest-character-mappings/${storyId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          script_characters: uniqueCharacters,
          project_characters: projectCharacters
        }),
      });

      if (!response.ok) throw new Error('Failed to get character mapping suggestions');
      
      const data = await response.json();
      setMappings(data.mappings);
    } catch (err) {
      console.error('Error getting character mapping suggestions:', err);
    }
  };

  const handleCharacterSelect = async (scriptCharacter, selectedValue) => {
    if (selectedValue === 'create-new') {
      navigate(`/new-character/${projectId}`);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/script-parsing/update-character-mapping`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          story_id: storyId,
          script_character: scriptCharacter,
          project_character_id: selectedValue || null
        }),
      });

      if (!response.ok) throw new Error('Failed to update character mapping');
      
      setMappings(prev => ({
        ...prev,
        [scriptCharacter]: selectedValue || null
      }));
    } catch (err) {
      setError('Failed to update character mapping');
    }
  };

  const handleVoiceClick = (characterId) => {
    if (characterId) {
      navigate(`/character-voice/${characterId}`);
    }
  };

  const getVoiceDisplay = (characterId) => {
    if (!characterId) return "No Character Selected";
    if (!voiceSettings[characterId]) return "No Voice";
    return voiceSettings[characterId].name || "Unnamed Voice";
  };

  if (isLoading) return <div style={styles.container}>Loading character mappings...</div>;
  if (error) return <div style={{...styles.error, marginTop: '20px'}}>{error}</div>;

  return (
    <div style={{ marginTop: '40px' }}>
      <h2 style={{...styles.formTitle, fontSize: '1.5rem', marginBottom: '20px'}}>
        Script Characters:
      </h2>
      <div style={{overflowX: 'auto', width: '100%'}}>
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          backgroundColor: 'rgba(255,255,255,0.05)',
        }}>
          <thead>
            <tr>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
              }}>Script Character</th>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
              }}>Related Project Character</th>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
              }}>Voice</th>
            </tr>
          </thead>
          <tbody>
            {scriptCharacters.map((scriptChar) => (
              <tr key={scriptChar}>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                }}>{scriptChar}</td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                }}>
                  <select
                    value={mappings[scriptChar] || ''}
                    onChange={(e) => handleCharacterSelect(scriptChar, e.target.value)}
                    style={{
                      ...styles.formInput,
                      width: '100%',
                      backgroundColor: 'rgba(255,255,255,0.1)',
                    }}
                  >
                    <option value="">Select Character</option>
                    {projectCharacters.map((char) => (
                      <option key={char.id} value={char.id}>
                        {char.name}
                      </option>
                    ))}
                    <option value="create-new">Create New Character</option>
                  </select>
                </td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                }}>
                  <div 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      cursor: mappings[scriptChar] ? 'pointer' : 'default',
                    }}
                    onClick={() => handleVoiceClick(mappings[scriptChar])}
                  >
                    {mappings[scriptChar] && <Volume2 size={16} />}
                    <span>{getVoiceDisplay(mappings[scriptChar])}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScriptCharactersTable;