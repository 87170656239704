// src/pages/ScriptAudioScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import ScriptCharactersTable from '../components/ScriptCharactersTable';
import VoiceValidationTable from '../components/VoiceValidationTable';
import ScriptAudioTable from '../components/ScriptAudioTable';

const ScriptAudioScreen = () => {
  const [story, setStory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parsedLines, setParsedLines] = useState([]);
  const [isParsing, setIsParsing] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [validationStatus, setValidationStatus] = useState(null);
  const { user } = useUser();
  const { storyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchStoryDetails();
      fetchParsedLines();
    } else {
      navigate('/login');
    }
  }, [storyId, user.isAuthenticated, navigate]);

  const fetchStoryDetails = async () => {
    if (!user.user_id || !storyId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/story/${storyId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch story details');
      }

      const data = await response.json();
      setStory(data.story);
      setProjectId(data.projectId);
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to fetch story details');
    }
  };

  const fetchParsedLines = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/script-parsing/get-parsed-lines/${storyId}?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch parsed lines');
      }

      const data = await response.json();
      setParsedLines(data.parsed_lines);

      // After getting parsed lines, check voice validation
      await checkVoiceValidation();
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to fetch parsed lines');
    } finally {
      setIsLoading(false);
    }
  };

  const checkVoiceValidation = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/script-voice-validation/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          story_id: storyId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate voices');
      }

      const data = await response.json();
      setValidationStatus(data);
    } catch (err) {
      console.error('Error checking voice validation:', err);
    }
  };

  const handleParse = async () => {
    setIsParsing(true);
    setError(null);

    try {
      const response = await fetch(`${API_BASE_URL}/script-parsing/parse-script`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to parse script');
      }

      await fetchParsedLines();
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to parse script');
    } finally {
      setIsParsing(false);
    }
  };

  const handleValidationResult = (result) => {
    setValidationStatus(result);
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!story) return <div style={styles.container}>Story not found.</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/story/${storyId}`} style={styles.link}>
          &lt;&lt; Back to Story
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Script Audio for {story.title}
      </h1>

      <div style={styles.formGroup}>
        <button
          onClick={handleParse}
          style={{...styles.button, marginBottom: '20px'}}
          disabled={isParsing}
        >
          {isParsing ? 'Parsing Script...' : 'Parse Script'}
        </button>

        {parsedLines.length > 0 && (
          <>
            <div style={{overflowX: 'auto', width: '100%', marginBottom: '40px'}}>
              <table style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginTop: '20px',
                backgroundColor: 'rgba(255,255,255,0.05)',
              }}>
                <thead>
                  <tr>
                    <th style={{
                      padding: '12px',
                      textAlign: 'left',
                      borderBottom: '2px solid #444',
                      backgroundColor: '#333',
                      whiteSpace: 'nowrap',
                    }}>Line</th>
                    <th style={{
                      padding: '12px',
                      textAlign: 'left',
                      borderBottom: '2px solid #444',
                      backgroundColor: '#333',
                    }}>Character</th>
                    <th style={{
                      padding: '12px',
                      textAlign: 'left',
                      borderBottom: '2px solid #444',
                      backgroundColor: '#333',
                    }}>Stage Directions</th>
                    <th style={{
                      padding: '12px',
                      textAlign: 'left',
                      borderBottom: '2px solid #444',
                      backgroundColor: '#333',
                    }}>Dialogue</th>
                  </tr>
                </thead>
                <tbody>
                  {parsedLines.map((line) => (
                    <tr key={line.line_number}>
                      <td style={{
                        padding: '12px',
                        borderBottom: '1px solid #444',
                        whiteSpace: 'nowrap',
                      }}>{line.line_number}</td>
                      <td style={{
                        padding: '12px',
                        borderBottom: '1px solid #444',
                      }}>{line.character_name}</td>
                      <td style={{
                        padding: '12px',
                        borderBottom: '1px solid #444',
                        fontStyle: 'italic',
                        color: '#999',
                      }}>{line.stage_directions}</td>
                      <td style={{
                        padding: '12px',
                        borderBottom: '1px solid #444',
                      }}>{line.dialogue_text}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <ScriptCharactersTable 
              storyId={storyId}
              parsedLines={parsedLines}
              projectId={projectId}
            />

            <VoiceValidationTable
              storyId={storyId}
              parsedLines={parsedLines}
              projectId={projectId}
              onValidationResult={handleValidationResult}
            />

            {validationStatus?.allValid && (
              <ScriptAudioTable
                storyId={storyId}
                parsedLines={parsedLines}
              />
            )}
          </>
        )}

        {!parsedLines.length && (
          <p style={{textAlign: 'center', color: '#666'}}>
            No parsed lines available. Click "Parse Script" to begin.
          </p>
        )}
      </div>
    </div>
  );
};

export default ScriptAudioScreen;