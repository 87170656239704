// src/pages/MyAccountScreen.js

import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';

function MyAccountScreen() {
  const { user } = useUser();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user.isAuthenticated) return;

      try {
        const response = await fetch(`${API_BASE_URL}/admin/check-admin-status?user_id=${user.user_id}`);
        
        if (!response.ok) {
          throw new Error('Failed to check admin status');
        }

        const data = await response.json();
        setIsAdmin(data.is_admin);
      } catch (err) {
        console.error('Error checking admin status:', err);
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminStatus();
  }, [user.isAuthenticated, user.user_id]);

  if (isLoading) return <div style={styles.container}>Loading...</div>;

  return (
    <div style={styles.container}>
      <h1 style={styles.sectionHeader}>My Account</h1>
      <div style={styles.accountDetails}>
        <p><strong>User Name:</strong> {user.userName}</p>
        <p><strong>Email:</strong> {user.email}</p>
        {isAdmin && (
          <p>
            <Link to="/admin" style={{
              ...styles.link,
              color: '#007BFF',
              textDecoration: 'none',
              display: 'inline-flex',
              alignItems: 'center',
              gap: '5px'
            }}>
              Access Admin Dashboard
            </Link>
          </p>
        )}
        <p>
          <Link to="/changepassword" style={styles.link}>Change Password</Link>
        </p>
        <p>
          <Link to="/deleteaccount" style={styles.link}>Delete Account</Link>
        </p>
      </div>
    </div>
  );
}

export default MyAccountScreen;