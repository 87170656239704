// src/pages/DefaultVoicesScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const DefaultVoicesScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [defaultVoices, setDefaultVoices] = useState([]);
  const [newVoice, setNewVoice] = useState({
    name: '',
    voice_id: '',
    gender: '',
    age: '',
    accent: '',
    description: '',
    use_case: '',
    preview_url: ''
  });
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminAccess = async () => {
      if (!user.isAuthenticated) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/admin/check-admin-status?user_id=${user.user_id}`);
        
        if (!response.ok) {
          throw new Error('Failed to verify admin status');
        }

        const data = await response.json();
        if (!data.is_admin) {
          navigate('/login');
          return;
        }
      } catch (err) {
        console.error('Error:', err);
        setError(err.message || 'An error occurred');
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminAccess();
  }, [user.isAuthenticated, user.user_id, navigate]);

  useEffect(() => {
    const fetchDefaultVoices = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/default-voice-settings/list-voices?user_id=${user.user_id}`);

        if (!response.ok) {
          throw new Error('Failed to fetch default voices');
        }

        const data = await response.json();
        setDefaultVoices(data.voices);
      } catch (err) {
        console.error('Error:', err);
        setError(err.message || 'An error occurred');
      }
    };

    fetchDefaultVoices();
  }, [user.user_id]);

  const handleInputChange = (e) => {
    setNewVoice({
      ...newVoice,
      [e.target.name]: e.target.value
    });
  };

  const handleAddVoice = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/default-voice-settings/add-voice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: user.user_id,
          ...newVoice
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to add default voice');
      }
  
      // Reset form
      setNewVoice({
        name: '',
        voice_id: '',
        gender: '',
        age: '',
        accent: '',
        description: '',
        use_case: '',
        preview_url: ''
      });
  
      // Fetch updated voices - FIX: Use correct endpoint path
      const listResponse = await fetch(`${API_BASE_URL}/default-voice-settings/list-voices?user_id=${user.user_id}`);
  
      if (!listResponse.ok) {
        throw new Error('Failed to fetch updated default voices');
      }
  
      const data = await listResponse.json();
      setDefaultVoices(data.voices);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    }
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to="/admin" style={styles.link}>
          &lt;&lt; Back to Admin Home
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Default Voices
      </h1>

      {defaultVoices.map((voice, index) => (
        <div key={index} style={{marginBottom: '20px'}}>
          <p><strong>Name:</strong> {voice.name}</p>
          <p><strong>Voice ID:</strong> {voice.voice_id}</p>
          <p><strong>Gender:</strong> {voice.gender}</p>
          <p><strong>Age:</strong> {voice.age}</p>
          <p><strong>Accent:</strong> {voice.accent}</p>
          <p><strong>Description:</strong> {voice.description}</p>
          <p><strong>Use Case:</strong> {voice.use_case}</p>
          <p>
            <strong>Preview:</strong>{' '}
            <audio controls src={voice.preview_url}>
              Your browser does not support the audio element.
            </audio>
          </p>
          <hr />
        </div>
      ))}

      <h2 style={{...styles.formTitle, textAlign: 'center', marginBottom: '20px'}}>
        Add Default Voice
      </h2>

      <div style={styles.formGroup}>
        <label style={styles.label}>Name:</label>
        <input
          type="text"
          name="name"
          value={newVoice.name}
          onChange={handleInputChange}
          style={styles.formInput}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Voice ID:</label>
        <input
          type="text"
          name="voice_id"
          value={newVoice.voice_id}
          onChange={handleInputChange}
          style={styles.formInput}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Gender:</label>
        <input
          type="text"
          name="gender"
          value={newVoice.gender}
          onChange={handleInputChange}
          style={styles.formInput}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Age:</label>
        <input
          type="text"
          name="age"
          value={newVoice.age}
          onChange={handleInputChange}
          style={styles.formInput}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Accent:</label>
        <input
          type="text"
          name="accent"
          value={newVoice.accent}
          onChange={handleInputChange}
          style={styles.formInput}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Description:</label>
        <input
          type="text"
          name="description"
          value={newVoice.description}
          onChange={handleInputChange}
          style={styles.formInput}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Use Case:</label>
        <input
          type="text"
          name="use_case"
          value={newVoice.use_case}
          onChange={handleInputChange}
          style={styles.formInput}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Preview URL:</label>
        <input
          type="text"
          name="preview_url"
          value={newVoice.preview_url}
          onChange={handleInputChange}
          style={styles.formInput}
        />
      </div>

      <button
        onClick={handleAddVoice}
        style={styles.button}
      >
        Add Default Voice
      </button>
    </div>
  );
};

export default DefaultVoicesScreen;